/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
window.FontAwesomeConfig = {
    // autoReplaceSvg: false,
    searchPseudoElements: true
};

window.utf8Decode = (s) => {
  return decodeURIComponent(escape(s));
}

// Cookies (Use in rgpd, ...)
window.getCookie = (name) => {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));

    return match && match.length ? match[2] : null;
}
window.setCookie = (name, value, expires) => {
    // document.cookie = 'rgpd=approved; expires=Fri, 3 Aug 2020 20:47:11 UTC; path=/';
    var d = new Date();
    d = new Date((d.getTime() + 1000) * expires);
    document.cookie = name + '=' + value + '; expires=' + d.toGMTString() + '; path=/;SameSite=Lax;';
}

// Common

window.now = (() => {
    var d = new Date();
    var year = d.getFullYear();
    var month = ('0' + (d.getMonth() + 1)).slice(-2);
    var day = ('0' + d.getDate()).slice(-2);

    return year + '-' + month + '-' + day + ' ' + d.getHours() + ':' + d.getMinutes() + ':00';
})();

// To diff time between visit/registration/payment.
if (!getCookie('_first_now')) {
    if (now) {
        setCookie('_first_now', now, 2629800 * 13); // 2629800 = 1 month
        setCookie('_first_ua', encodeURIComponent(navigator.userAgent), 2629800 * 13);
        setCookie('_first_width', window.screen.width, 2629800 * 13);
        setCookie('_first_height', window.screen.height, 2629800 * 13);
        setCookie('_first_pixelDepth', window.screen.pixelDepth, 2629800 * 13);
        setCookie('_first_href', encodeURI(window.location.href).replace(/#.*/g, ''), 2629800 * 13);
        setCookie('_first_referer', encodeURI(document.referrer).replace(/#.*/g, ''), 2629800 * 13); // TODO What about the HTTPS redirect?
    }
}

// @see analytics.blade.php
// window.dataLayer = window.dataLayer || [];
// window.gtag = (() => {
//     // console.info(arguments);
//     if (typeof dataLayer != 'undefined') {
//         dataLayer.push(arguments);
//     } else {
//         console.error('[google] dataLayer is undefined!', arguments);
//     }
// });

window.tooltip = (() => {
  if (typeof $('[data-toggle="tooltip"]').tooltip != 'undefined') {
    return $('[data-toggle="tooltip"]').tooltip({
        html: true
    });
  }
});

$(() => {
  setInterval(tooltip, 2000);
});

$(window).scroll(() => {
  // console.error('scrollTop', $(document).scrollTop() > 20, $(document).scrollTop());
  if ($(document).scrollTop() > 20) {
      $('.fixed-top').removeClass('navbar-shrink');
  } else {
      $('.fixed-top').addClass('navbar-shrink');
  }
});

window.renderExternalIco = (() => {
  // noopener noreferrer
  $externals = $('.external');
  for (var i = 0; i < $externals.length; i++) {
    var $el = $($externals[i]);
    if ($el.attr('target') !== '_blank') {
        if ($el.hasClass('no-ico')) {
            $el.attr('target', '_blank').attr('rel', 'noopener');
        } else {
            $el.append('&nbsp;<i class="fas fa-external-link-alt fs-smaller" aria-hidden="true"></i>')
                .attr('target', '_blank')
                .attr('rel', 'noopener');
        }
    }
  }
  $externals = $('.external-pdf');
  for (var i = 0; i < $externals.length; i++) {
    var $el = $($externals[i]);
    if ($el.attr('target') !== '_blank') {
        $el.prepend('<i class="fas fa-file-pdf fs-smaller text-danger ml-1" aria-hidden="true"></i>&nbsp;')
            .attr('target', '_blank')
            .attr('rel', 'noopener');
    }
  }
});
$(() => {
    renderExternalIco();
});

$(() => {
    $('span.btn-share').on('click', () => {
        var href = $(this).data('href');
        var w = 500;
        var h = 400;
        var left = (screen.width / 2) - (w / 2);
        var top = (screen.height / 2) - (h / 2);

        console.error('span.btn-share', href);

        window.open(href, '', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=no, top=' + top + ',left=' + left + ',width=' + w + ',height=' + h);
    });
});

$(() => {
    $('.form-date-with-slashes').keyup(e => {
        e.preventDefault();
        var pos = this.selectionStart;
        var keycode = e.which || e.keyCode;
        var $el = $(e.currentTarget);
        var val = $el.val();
        if (val) {
            if (keycode != 8) { // 8 = BACKSPACE
                val = val.replace(/^([0-9]{2})$/g, '$1/');
                val = val.replace(/^([0-9]+)\/([0-9]{2})$/g, '$1/$2/');
            }
            // console.error(pos, val, e.keyCode);
            $el.val(val);

            if (!/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/g.test(val)) {
                $el.addClass('is-invalid');
            } else {
                $el.removeClass('is-invalid');
            }
        }
    });
});

window.navByAnchor = (() => {
  // Hash navigation
  let hash = window.location.hash;
  if (hash == '#/undefined' || hash == '#/') {
    hash = null;
  }
  // console.error('hash', hash, window.location.hash);

  // Tab anchor navigation (nav-tabs)
  $('.nav-tabs .nav-link').click(e => {
    e.preventDefault();

    let h = this.hash;
    if (! h && e.target) {
      h = $(e.target)[0].hash;
    }
    // console.error('h', h, hash, window.location.hash);
    if (h) {
      window.location.hash = h;
    }
  });
  if (hash) {
    let $el = $(`.nav-tabs .nav-link[href="${hash}"]`);
    if (typeof $el.tab != 'undefined') { // == 'function'
      $el.tab('show');
    }
    hash = hash.replace(/#\//, '#');
    $el = $(`.nav-tabs .nav-link[href="${hash}"]`);
    if (typeof $el.tab != 'undefined') {
      $el.tab('show');
    }
    // console.error(`.nav-tabs .nav-link[href="${hash}"]`, $(`.nav-tabs .nav-link[href="${hash}"]`));
  } else {
      $el = $('.nav-tabs .nav-link:first');
      if (typeof $el.tab != 'undefined') {
        $el.tab('show');
      }
      // console.error(`.nav-tabs .nav-link:first`);
  }

  return hash;
});

$(() => {
  navByAnchor();
});

if ("onhashchange" in window) {
  window.onhashchange = (() => {
    navByAnchor();
  });
}

window.hasClass = (el, classname) => el && el.className && el.className.split(' ').indexOf(classname) >= 0;

window.parentWithClass = (el, classname) => {
    if (hasClass(el, classname)) {
        return el;
    }

    return el && el.parentNode && parentWithClass(el.parentNode, classname);
}

// // //cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3/jquery.easing.min.js
// // //cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js
// require('jquery.easing');

window.scrollTo = (target) => {
  setTimeout(() => {
    if ($(target).length) {
      $('html, body').stop().animate({
        scrollTop: $(target).offset().top - 75
      }, 1000, 'easeInOutExpo');
    } else {
      // console.error('scrollTo', target);
    }
  }, 300);
};

let pageScrollId = null;
let updatePageScroll = (() => {
  let n = 0;
  $('.page-scroll').each(() => {
      if (!$(this).data('scrollable')) {
          $(this).bind('click', (event) => {
              var $anchor = $(this);
              var target = $anchor.attr('href');
              if ($anchor.data('href')) {
                  target = $anchor.data('href');
              }
              scrollTo(target);
              event.preventDefault();
          });
          $(this).data('scrollable', 1);
          n++;
      }
  });
  clearTimeout(pageScrollId);
  pageScrollId = setTimeout(updatePageScroll, 200);

  return true;
});

window.updatePageScroll = updatePageScroll;

$(() => {
    updatePageScroll();
});

window.Accordion = {
    $container: null,
    $links: [],
    init: (target) => {
        this.$container = $('#' + target);
        if (this.$container) {
            this.$links = this.$container.find('.accordion');
            this.$links.each(() => {
                $(this).on('click', (e) => {
                    Accordion.click($(this));
                });
            });
        }

        return true;
    },
    click: ($el) => {
        this.hide();
        var href = $el.attr('href');
        var $target = $(href);
        if ($target.is(':visible')) {
            $target.hide();
        } else {
            $target.show();
        }
    },
    hide: () => {
        this.$links.each(() => {
            var href = $(this).attr('href');
            var $target = $(href);
            $($target).hide();
        });

        return true;
    }
};
$(() => {
    Accordion.init('accordion');
})

// module.exports = {
//   methods: Accordion.init
// };

// Message from parent window (iframe)
function quitQcm() {
  /*
  2020-09-15 Debug. Display swich portrait/landscape corrupted w iphone.
  return false;
  */
  if (true || $('body').hasClass('body-with-qcm')) {
    console.log('[QCM] quit', arguments);

    /*
    // TypeError: window.gtag is not a function
    window.gtag('event', 'qcm', {
        'event_category': 'close',
        'event_label': window.location.hash
    });
    */

    $('body').removeClass('body-with-qcm');

    if (arguments.length && arguments[0] == 'menu') {
      return false;
    }

    $('body').removeClass('registration');
    $('body').css('overflow', 'auto');
    $('.mcq-modal-histo').hide();

    let win = window.parent;
    let isNotParent = RegExp('^#/qcm/').test(window.location.hash);
    if (isNotParent || !win) {
      win = window;
    }
    let isHash = RegExp('^#/qcm/').test(win.location.hash);
    let isLaunch = !win.length;
    if (isHash || window.history.length > 1) {
      if (window.history.length > 1) {
        window.history.go(-1);
      } else {
        window.location.href = '';
      }
    } else if (isLaunch) {
        window.opener = window;
        window.close();
    }
  } else {
      console.log('[QCM] already closed');
      // Just is case... @see /u/c/23-statistiques
      $('.mcq-modal-histo').hide();
      $('body').css('overflow', 'auto');
  }
}

window.openQcm = (() => {

    console.log('[QCM] open', arguments);
    // window.scrollTo('body', 0);

    // Doc PDF de Xavier
    var daQcm = document.getElementById('idFrameDuQcm');
    if (daQcm && daQcm.contentWindow) {
      window.addEventListener("keydown", daQcm.contentWindow.onQcmKeyEvent);
    }

    $('body').addClass('body-with-qcm');
    $('body').css('overflow', 'hidden');

    if (/free/.test(window.location.hash)) {
        $('body').addClass('registration');

        $('html, body').scrollTop(0);
    }

    // TypeError: window.gtag is not a function
    // window.gtag('event', 'qcm', {
    //     'event_category': 'open',
    //     'event_label': window.location.hash
    // });

    $('.body-with-qcm').find('.navbar').find('a[href]').click(e => {
        // Navbar btns
        quitQcm('menu');
    });
    $('.body-with-qcm').find('.navbar').find('a[onclick]').on('click', (e) => {
        // Logout btn
        quitQcm('menu');
    });
});

// Show password
let showPwd = false;
window.switchPwd = (() => {
    showPwd = !showPwd;
    if (showPwd) {
        $('#show-password').find('i').addClass('fa-eye-slash').removeClass('fa-eye');
        $('input[name=password]').attr('type', 'text');
        $('input[name=password_confirmation]').attr('type', 'text');
    } else {
        $('#show-password').find('i').addClass('fa-eye').removeClass('fa-eye-slash');
        $('input[name=password]').attr('type', 'password');
        $('input[name=password_confirmation]').attr('type', 'password');
    }
});

// https://developer.mozilla.org/fr/docs/Web/API/Window/postMessage
// window.parent.postMessage('qcmQuit',"*");
window.addEventListener("message", receiveMessage, false);

/**
 * @param MessageEvent e
 */
function receiveMessage(e) {
    if (e.data) {
        // console.error('receiveMessage', e);
    }
    if (e.data == 'qcmQuit') {
        quitQcm();
    }
}

// Right click on video
setInterval(() => {
    $('video').bind('contextmenu', (e) => {
        return false;
    });
}, 500);

// User-Agent
axios.get(`/xhr/ua?w=${window.screen.width}&h=${window.screen.height}&pd=${window.screen.pixelDepth}&href=${encodeURI(window.location.href).replace(/#.*/g, '')}&referer=${encodeURI(document.referrer).replace(/#.*/g, '')}`)
    .then(response => {
        // console.error(response);
    })
    .catch(error => {
        console.error(error);
    });
